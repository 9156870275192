import React from "react"

import Layout from '../components/layout'
import BetView from '../components/bet_view'
import Head from '../components/head'

const bets = () => {
    return (
        <Layout>
            <Head title="Betting Card" pageDescription="Betting card of +EV NFL plays"/>
            <BetView />
        </Layout>
    )
}

export default bets


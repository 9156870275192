import React from "react"
import {graphql, useStaticQuery} from "gatsby"

import CardRow from '../helper_js/display_card_row'

import betStyles from './bet_view.module.scss'


const BetView = () => { 
    const data = useStaticQuery(graphql`
        query {
            allProjectedSpreadsCsv (
                sort: {
                    fields:[
                    sort
                    ]
                    order: DESC
                }
            ) {
            nodes {
                game_id
                season
                week
                home_team_name
                away_team_name
                home_team_logo_espn
                away_team_logo_espn
                home_line_close
                home_closing_line_rounded_nfelo
                home_ev
                away_ev
                home_probability_nfelo
            }
            }
        }
    `)
    return (
        <div className={betStyles.cardContainer}>
            <h3 className={betStyles.playType}>+EV Plays</h3>
            <div className={betStyles.headerDiv}>
                <table className={betStyles.header_table}>
                    <colgroup>
                        <col width='13%'></col>
                        <col width='4%'></col>
                        <col width='13%'></col>
                        <col width='15%'></col>
                        <col width='15%'></col>
                        <col width='15%'></col>
                        <col width='15%'></col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                Away
                            </th>
                            <th>
                            </th>
                            <th>
                                Home
                            </th>
                            <th>
                                Market Line
                            </th>
                            <th>
                                Model Line
                            </th>
                            <th>
                                Away EV
                            </th>
                            <th>
                                Home EV
                            </th>
                        </tr>
                    </thead>
                </table>
            </div>
            <div className={betStyles.cardDiv}>
                <table className={betStyles.cardTable}>
                    <colgroup>
                        <col width='13%'></col>
                        <col width='4%'></col>
                        <col width='13%'></col>
                        <col width='15%'></col>
                        <col width='15%'></col>
                        <col width='15%'></col>
                        <col width='15%'></col>
                    </colgroup>
                    <tbody>
                        {data.allProjectedSpreadsCsv.nodes.map(node => {
                            return (
                                <CardRow
                                    node={node}
                                    is_pos={true}
                                />
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className={betStyles.spacerDiv}></div>
            <h3 className={betStyles.playType}>-EV Passes</h3>
            <div className={betStyles.headerDiv}>
                <table className={betStyles.header_table}>
                    <colgroup>
                        <col width='13%'></col>
                        <col width='4%'></col>
                        <col width='13%'></col>
                        <col width='15%'></col>
                        <col width='15%'></col>
                        <col width='15%'></col>
                        <col width='15%'></col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                Away
                            </th>
                            <th>
                            </th>
                            <th>
                                Home
                            </th>
                            <th>
                                Market Line
                            </th>
                            <th>
                                Model Line
                            </th>
                            <th>
                                Away EV
                            </th>
                            <th>
                                Home EV
                            </th>
                        </tr>
                    </thead>
                </table>
            </div>
            <div className={betStyles.cardDiv}>
                <table className={betStyles.cardTable}>
                    <colgroup>
                        <col width='13%'></col>
                        <col width='4%'></col>
                        <col width='13%'></col>
                        <col width='15%'></col>
                        <col width='15%'></col>
                        <col width='15%'></col>
                        <col width='15%'></col>
                    </colgroup>
                    <tbody>
                        {data.allProjectedSpreadsCsv.nodes.map(node => {
                            return (
                                <CardRow
                                    node={node}
                                    is_pos={false}
                                />
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default BetView


import React from 'react'

import set_ev_color from './set_ev_color'
import gameBoxStyles from '../components/games_box.module.scss'
import betStyles from '../components/bet_view.module.scss'

const CardRow = ( { node, is_pos }) => {

    var home_display_ev = (node.home_ev * 100).toFixed(1) + '%'
    var away_display_ev = (node.away_ev * 100).toFixed(1) + '%'
    var home_display_style = set_ev_color(node.home_ev)
    var away_display_style = set_ev_color(node.away_ev)

        if (parseFloat(node.home_ev) >= parseFloat(node.away_ev)) {
            away_display_ev = ''
            away_display_style.backgroundColor = 'white'
        } else {
            home_display_ev = ''
            home_display_style.backgroundColor = 'white'
        }


    if ((node.home_ev >= 0 || node.away_ev >= 0) && is_pos) {


        return (
                <tr>
                    <td>
                        <div>
                            <img
                                className={gameBoxStyles.team_logo}
                                src={node.away_team_logo_espn}
                                alt={node.away_team_name}>
                            </img>
                        </div>
                    </td>
                    <td className={betStyles.atSign}>
                        @
                    </td>
                    <td className={betStyles.rowBorderRight}>
                        <div>
                            <img
                                className={gameBoxStyles.team_logo}
                                src={node.home_team_logo_espn}
                                alt={node.home_team_name}>
                            </img>
                        </div>
                    </td>
                    <td>
                        {Math.round(10 * node.home_line_close)/10}
                    </td>
                    <td className={betStyles.rowBorderRight}>
                        {Math.round(10*node.home_closing_line_rounded_nfelo)/10}
                    </td>
                    <td style={away_display_style}>
                        {away_display_ev}
                    </td>
                    <td style={home_display_style}>
                        {home_display_ev}
                    </td>
                </tr>

        )
    
    } else if ((node.home_ev < 0 && node.away_ev < 0) && !is_pos) {

        return (

            <tr>
                <td>
                    <div>
                        <img
                            className={gameBoxStyles.team_logo}
                            src={node.away_team_logo_espn}
                            alt={node.away_team_name}>
                        </img>
                    </div>
                </td>
                <td className={betStyles.atSign}>
                        @
                </td>
                <td className={betStyles.rowBorderRight}>
                    <div>
                        <img
                            className={gameBoxStyles.team_logo}
                            src={node.home_team_logo_espn}
                            alt={node.home_team_name}>
                        </img>
                    </div>
                </td>
                <td>
                    {Math.round(10 * node.home_line_close)/10}
                </td>
                <td className={betStyles.rowBorderRight}>
                    {Math.round(10*node.home_closing_line_rounded_nfelo)/10}
                </td>
                <td style={away_display_style}>
                    {away_display_ev}
                </td>
                <td style={home_display_style}>
                    {home_display_ev}
                </td>
            </tr>
        )

    } else {

        return (null)
    }

}

export default CardRow
// function for setting cell color based on percentage //

import tinycolor from 'tinycolor2'

const set_ev_color = (pct) => {

    const neg_color = '#ffabc0'
    const neg_text = '#c22d52'
    const zero_color = '#ffffff'
    const pos_color = '#96ffdd'
    const pos_text = '#26b588'

    if (pct <= 0) {
        return {
            backgroundColor : tinycolor.mix(zero_color, neg_color, Math.abs(pct) * 100),
            color : neg_text
        }
    } else {
        return {
            backgroundColor : tinycolor.mix(zero_color, pos_color, Math.abs(pct) * 100),
            color : pos_text
        }
    }
    
}

export default set_ev_color